import React from 'react';
import Loadable from 'react-loadable';
import {
  Link,
  Paragraph,
  PlatformTab,
  PropList,
  PropListItem,
  Section,
} from '../../../components';

const Text = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Text;
    return <Component {...props} />;
  },
});

const WebTab = () => {
  return (
    <PlatformTab>
      <Section title="Props">
        <Paragraph>
          The video player component is housed within our video-interface repo.
          Head there to read the list of props available for each component and{' '}
          <Link href="https://github.com/hudl/js-hudl-video-interface/wiki/Contributing">
            fire up the demo
          </Link>
          .
        </Paragraph>
        <PropList header="Miscellaneous">
          <PropListItem name="...props" types={[]}>
            <Text>
              You can pass in any props from the{' '}
              <Link href="https://github.com/hudl/js-hudl-video-interface/blob/master/docs/components/Row.md">
                Row
              </Link>
              ,{' '}
              <Link href="https://github.com/hudl/js-hudl-video-interface/blob/master/docs/components/Video.md">
                Video
              </Link>
              , and{' '}
              <Link href="https://github.com/hudl/js-hudl-video-interface/blob/master/docs/components/VideoProvider.md">
                VideoProvider
              </Link>{' '}
              components.
            </Text>
          </PropListItem>
        </PropList>
      </Section>
    </PlatformTab>
  );
};

export default WebTab;
